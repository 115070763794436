











































































import { Component, Prop, Vue } from "vue-property-decorator";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import { ifValid, processError } from "@/utils/ComponentUtils";
import {
  ApproveRequestPayload,
  ChangeStatusVCRRequestPayload,
  RejectRequestPayload,
  VacationDto,
  VCR,
  VCRStatus
} from "@/dto/payroll/VacationDTO";
import PortalSelect from "@/components/common/PortalSelect.vue";
import { WorkspaceType } from "@/dto/auth/Workspace";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import { EventBus } from "@/utils/EventBus";

@Component<VacationRequestDetails>({
  components: { PortalTextarea, PortalSelect },
  computed: {
    WorkspaceType() {
      return WorkspaceType;
    },
    VCRStatus() {
      return VCRStatus;
    }
  }
})
export default class VacationRequestDetails extends Vue {

  @Prop({ default: null })
  vacation!: VacationDto | null;

  @Prop({ default: null })
  vacationRequest!: VCR | null;

  private comment: string | null = null;

  private statusPayload = new ChangeStatusVCRRequestPayload({
    requestId: this.vacationRequest?.id,
    employmentId: this.id,
    status: VCRStatus.CANCELLED,
  });

  private approvePayload = new ApproveRequestPayload({
    requestId: this.vacationRequest?.id,
    companyId: this.id,
  });

  private rejectPayload = new RejectRequestPayload({
    requestId: this.vacationRequest?.id,
    companyId: this.id,
  });

  private setCancelledStatus() {
    ifValid(this, async () => {
      try {
        Application.startLoading();
        await VacationService.changeStatus(this.statusPayload);
        EventBus.$emit('changeStatusVacationRequest');
      } catch (err) {
        processError(err, this);
      } finally {
        Application.stopLoading();
      }
    });
  }

  private setApprovedStatus() {
    ifValid(this, async () => {
      try {
        Application.startLoading();
        this.approvePayload.comment = this.comment;
        await VacationService.approveRequest(this.approvePayload);
        EventBus.$emit('changeStatusVacationRequest');
      } catch (err) {
        processError(err, this);
      } finally {
        Application.stopLoading();
      }
    });
  }

  private setRejectedStatus() {
    ifValid(this, async () => {
      try {
        Application.startLoading();
        this.rejectPayload.comment = this.comment;
        await VacationService.rejectRequest(this.rejectPayload);
        EventBus.$emit('changeStatusVacationRequest');
      } catch (err) {
        processError(err, this);
      } finally {
        Application.stopLoading();
      }
    });
  }

  get id(): number {
    return this.$wss.getCurrent.id;
  }

}
